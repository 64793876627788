import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';

const SciencePage = () => {
  const [scienceData, setScienceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  // State for new science entry
  const [newScience, setNewScience] = useState({
    text1: '',
    text2: '',
    link: '',
    image: null,
  });

  // State for edit data
  const [editData, setEditData] = useState({
    id: null,
    text1: '',
    text2: '',
    link: '',
    image: null,
  });

  // Modal states
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    fetchScienceData();
  }, []);

  const fetchScienceData = async () => {
    try {
      const response = await fetch('https://mastitisapi.goigi.me/api/science');
      const data = await response.json();
      setScienceData(data);
    } catch (error) {
      console.error('Error fetching science data:', error);
    }
  };

  // Add Modal handlers
  const handleAddClose = () => setShowAddModal(false);
  const handleAddShow = () => setShowAddModal(true);

  const handleAddSubmit = async () => {
    try {
      if (!newScience.text1 || !newScience.text2 || !newScience.link || !newScience.image) {
        Swal.fire({
          text: 'Please fill in all required fields.',
          icon: 'error',
        });
        return;
      }

      const formData = new FormData();
      formData.append('text1', newScience.text1);
      formData.append('text2', newScience.text2);
      formData.append('link', newScience.link);
      formData.append('image', newScience.image);

      const response = await fetch('https://mastitisapi.goigi.me/api/science', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        Swal.fire({
          title: 'Success!',
          text: 'Science entry added successfully',
          icon: 'success',
        });
        handleAddClose();
        fetchScienceData();
        setNewScience({ text1: '', text2: '', link: '', image: null });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to add science entry',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error adding science entry:', error);
    }
  };

  // Edit Modal handlers
  const handleEditClose = () => setShowEditModal(false);
  const handleEditShow = async (id) => {
    try {
      const response = await fetch(`https://mastitisapi.goigi.me/api/science/${id}`);
      const data = await response.json();

      setEditData({
        id: data._id,
        text1: data.text1,
        text2: data.text2,
        link: data.link,
        image: data.image,
      });

      setShowEditModal(true);
    } catch (error) {
      console.error('Error fetching science entry by ID:', error);
    }
  };

  const handleEditSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('text1', editData.text1);
      formData.append('text2', editData.text2);
      formData.append('link', editData.link);

      if (editData.image instanceof File) {
        formData.append('image', editData.image);
      } else {
        const existingImageBlob = await fetch(`https://mastitisapi.goigi.me/static/${editData.image}`).then(r => r.blob());
        const existingImageFile = new File([existingImageBlob], editData.image);
        formData.append('image', existingImageFile);
      }

      const response = await fetch(`https://mastitisapi.goigi.me/api/science/${editData.id}`, {
        method: 'PATCH',
        body: formData,
      });

      if (response.ok) {
        Swal.fire({
          title: 'Success!',
          text: 'Science entry updated successfully',
          icon: 'success',
        });
        handleEditClose();
        fetchScienceData();
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to update science entry',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error updating science entry:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      if (result.isConfirmed) {
        const response = await fetch(`https://mastitisapi.goigi.me/api/science/${id}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          Swal.fire({
            title: 'Success!',
            text: 'Science entry deleted successfully',
            icon: 'success',
          });
          fetchScienceData();
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Failed to delete science entry',
            icon: 'error',
          });
        }
      }
    } catch (error) {
      console.error('Error deleting science entry:', error);
    }
  };

  // File handling
  const handleAddFileChange = (e) => {
    setNewScience({
      ...newScience,
      image: e.target.files[0],
    });
  };

  const handleEditFileChange = (e) => {
    setEditData({
      ...editData,
      image: e.target.files[0],
    });
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = scienceData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">SCIENCE / UNIVERSITY</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Science</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-2">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border">
                <div className="card-body">
                  <div className="">
                    <div className="dataTables_length">
                      <div></div>
                      <div className="search-box">
                        <Button variant="primary" className="my-2" onClick={handleAddShow}>
                          Add New
                        </Button>
                        <Modal show={showAddModal} onHide={handleAddClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Add New </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group>
                                <Form.Label>University Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter University Name"
                                  value={newScience.text1}
                                  onChange={(e) => setNewScience({ ...newScience, text1: e.target.value })}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter State"
                                  value={newScience.text2}
                                  onChange={(e) => setNewScience({ ...newScience, text2: e.target.value })}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Link</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Link"
                                  value={newScience.link}
                                  onChange={(e) => setNewScience({ ...newScience, link: e.target.value })}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Upload Image</Form.Label>
                                <Form.Control 
                                  type="file" 
                                  onChange={handleAddFileChange}
                                />
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleAddClose}>
                              Close
                            </Button>
                            <Button variant="primary" onClick={handleAddSubmit}>
                              Save
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>

                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th>University Name</th>
                          <th>State</th>
                          <th>Link</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((data) => (
                          <tr key={data._id}>
                            <td>{data.text1}</td>
                            <td>{data.text2}</td>
                            <td>{data.link}</td>
                            <td>
                              <div className="icon-container">
                                <Button
                                  variant="info"
                                  className="btn-sm text-white"
                                  onClick={() => handleEditShow(data._id)}
                                >
                                  <FaRegEdit title="Edit" />
                                </Button>
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  onClick={() => handleDelete(data._id)}
                                >
                                  <FaTrashAlt title="Delete" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <div className="pagination">
                      {Array.from(
                        { length: Math.ceil(scienceData.length / itemsPerPage) },
                        (_, index) => (
                          <Button
                            className="mx-1"
                            key={index + 1}
                            onClick={() => paginate(index + 1)}
                          >
                            {index + 1}
                          </Button>
                        )
                      )}
                    </div>
                    <div className="container"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Science Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>University Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter University Name"
                value={editData.text1}
                onChange={(e) => setEditData({ ...editData, text1: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter State"
                value={editData.text2}
                onChange={(e) => setEditData({ ...editData, text2: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Link"
                value={editData.link}
                onChange={(e) => setEditData({ ...editData, link: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Upload Image</Form.Label>
              <Form.Control 
                type="file" 
                onChange={handleEditFileChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SciencePage;