import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Constants } from 'src/Constants'
import Swal from 'sweetalert2'
import useAuthStore from 'src/hooks/useAuthStore'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const Settings = () => {
  const [socialLinks, setSocialLinks] = useState({
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: '',
    phoneNumber: '',
    whatsappNumber: '',
    email: '',
    linkedin: '',
    address: '',
    boxLayoutUpperText: '',
    boxLayoutLowerText: '',
    dairyText: '',
    footerText: '',
    belowProductTitle: '',
    belowProductHeading1: '',
    belowProductDes1: '',
    belowProductHeading2: '',
    belowProductDes2: '',
    belowProductHeading3: '',
    belowProductDes3: '',

    productInsightsTitle: '',
    productInsightsHeading1: '',
    productInsightsDes1: '',
    productInsightsITDtitle: '',
    productInsightsITDdes: '',
    productInsightsdPlugtitle: '',
    productInsightsdPlugdes: '',
    productInsightsdCaretitle: '',
    productInsightsdCaredes: '',
  })
  const { token } = useAuthStore()
  const [adminData, setAdminData] = useState({
    username: '',
    password: '',
  })
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordMismatchError, setPasswordMismatchError] = useState('')

  // const token = "e.."

  useEffect(() => {
    // Fetch social links data when the component mounts
    const fetchSocialLinks = async () => {
      try {
        const response = await fetch(Constants.getAllSocialLinks)
        const data = await response.json()

        setSocialLinks(data)
      } catch (error) {
        console.error('Error fetching social links:', error)
      }
    }

    fetchSocialLinks()
  }, [])

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await fetch(Constants.getAdminDetails, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'admin-auth-token': token,
          },
        })

        if (response.ok) {
          const data = await response.json()
          setAdminData(data)
        } else {
          console.error('Failed to fetch admin data')
        }
      } catch (error) {
        console.error('Error fetching admin data', error)
      }
    }

    if (token) {
      fetchAdminData()
    }
  }, [token])

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value)
  }

  const handleSaveProfileSettings = async () => {
    try {
      if (adminData?.password !== confirmPassword) {
        setPasswordMismatchError('* Password does not match')
        return
      }

      setPasswordMismatchError('Password matches')

      const response = await fetch(Constants.registerOrUpdateAdmin, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'admin-auth-token': token,
        },
        body: JSON.stringify({
          username: adminData?.username,
          password: adminData?.password,
        }),
      })

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Profile details saved successfully',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to save profile details',
        })
      }
    } catch (error) {
      console.error('Error saving profile details:', error)
    }
  }

  const handleSaveSettings = async () => {
    try {
      const response = await fetch(Constants.createAndUpdateSocialLinks, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(socialLinks),
      })

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Settings saved successfully',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to save settings',
        })
      }
    } catch (error) {
      console.error('Error saving settings:', error)
    }
  }
  const handleUsernameChange = (e) => {
    setAdminData((prevData) => ({
      ...prevData,
      username: e.target.value,
    }))
  }

  const handlePasswordChange = (e) => {
    setAdminData((prevData) => ({
      ...prevData,
      password: e.target.value,
    }))
  }
  const editorConfig = {
    toolbar: ['bold', 'italic', 'underline', 'link', '|', 'undo', 'redo'],
    removePlugins: [
      'MediaEmbed',
      'Table',
      'TableToolbar',
      'CKFinderUploadAdapter',
      'CKFinder',
      'EasyImage',
      'Image',
      'ImageCaption',
      'ImageStyle',
      'ImageToolbar',
      'ImageUpload',
    ],
  }

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">SETTINGS</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active text-info">Settings</li>
                    </ol>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={16}>
                <div className="card custom-shadow rounded-lg border my-2 mb-4">
                  <div className="card-body">
                    <h5 className="mb-4" style={{ fontWeight: 'bold' }}>Profile Settings</h5>
                    <Form>
                      <Form.Group controlId="username">
                        <Form.Label style={{ fontWeight: 'bold' }}>UserName</Form.Label>
                        <Form.Control
                          type="email"
                          value={adminData?.username}
                          onChange={handleUsernameChange}
                        />
                      </Form.Group>

                      <Form.Group controlId="password">
                        <Form.Label style={{ fontWeight: 'bold' }}>Password</Form.Label>
                        <Form.Control
                          type="password"
                          value={adminData?.password}
                          onChange={handlePasswordChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="confirmPassword">
                        <Form.Label style={{ fontWeight: 'bold' }}>Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                        />
                        {passwordMismatchError && (
                          <p
                            style={{
                              color: passwordMismatchError.includes('not') ? 'red' : 'green',
                            }}
                          >
                            {passwordMismatchError}
                          </p>
                        )}
                      </Form.Group>

                      <Button
                        variant="primary"
                        className="my-2"
                        onClick={handleSaveProfileSettings}
                      >
                        Save Profile Details
                      </Button>
                    </Form>
                  </div>
                </div>
                <div className="card custom-shadow rounded-lg border my-2">
                  <div className="card-body">
                    <h5 className="mb-4" style={{ fontWeight: 'bold' }}>Social Links Settings</h5>
                    <Form>
                      <Form.Group controlId="facebook">
                        <Form.Label style={{ fontWeight: 'bold' }}>Facebook URL</Form.Label>
                        <Form.Control
                          type="url"
                          value={socialLinks?.facebook}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, facebook: e.target.value }))
                          }
                        />
                      </Form.Group>

                      <Form.Group controlId="twitter">
                        <Form.Label style={{ fontWeight: 'bold' }}>Twitter URL</Form.Label>
                        <Form.Control
                          type="url"
                          value={socialLinks?.twitter}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, twitter: e.target.value }))
                          }
                        />
                      </Form.Group>

                      <Form.Group controlId="instagram">
                        <Form.Label style={{ fontWeight: 'bold' }}>Instagram URL</Form.Label>
                        <Form.Control
                          type="url"
                          value={socialLinks?.instagram}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, instagram: e.target.value }))
                          }
                        />
                      </Form.Group>

                      <Form.Group controlId="youtube">
                        <Form.Label style={{ fontWeight: 'bold' }}>Youtube URL</Form.Label>
                        <Form.Control
                          type="url"
                          value={socialLinks?.youtube}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, youtube: e.target.value }))
                          }
                        />
                      </Form.Group>

                      <Form.Group controlId="phoneNumber">
                        <Form.Label style={{ fontWeight: 'bold' }}>Contact No</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.phoneNumber}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, phoneNumber: e.target.value }))
                          }
                        />
                      </Form.Group>

                      <Form.Group controlId="whatsappNumber">
                        <Form.Label style={{ fontWeight: 'bold' }}>Whatsapp No</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.whatsappNumber}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, whatsappNumber: e.target.value }))
                          }
                        />
                      </Form.Group>

                      <Form.Group controlId="emailLink">
                        <Form.Label style={{ fontWeight: 'bold' }}>Email Link</Form.Label>
                        <Form.Control
                          type="email"
                          value={socialLinks?.email}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, email: e.target.value }))
                          }
                        />
                      </Form.Group>

                      <Form.Group controlId="linkedin">
                        <Form.Label style={{ fontWeight: 'bold' }}>LinkedIn URL</Form.Label>
                        <Form.Control
                          type="url"
                          value={socialLinks?.linkedin}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, linkedin: e.target.value }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="address">
                        <Form.Label style={{ fontWeight: 'bold' }}>Address </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={socialLinks?.address}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, address: e.target.value }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="boxLayoutUpperText">
                        <Form.Label style={{ fontWeight: 'bold' }}>Box Layout Upper Text</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.boxLayoutUpperText}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({
                              ...prev,
                              boxLayoutUpperText: e.target.value,
                            }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="boxLayoutLowerText">
                        <Form.Label style={{ fontWeight: 'bold' }}>Box Layout Lower Text</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.boxLayoutLowerText}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({
                              ...prev,
                              boxLayoutLowerText: e.target.value,
                            }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="dairyText">
                        <Form.Label style={{ fontWeight: 'bold' }}>Dairy Text</Form.Label>
                        <div className="editor-container border rounded">
                          <CKEditor
                            editor={ClassicEditor}
                            config={editorConfig}
                            data={socialLinks?.dairyText || ''}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              setSocialLinks((prev) => ({ ...prev, dairyText: data }))
                            }}
                          />
                        </div>
                      </Form.Group>

                      <Form.Group controlId="footerText" className='mt-2'>
                        <Form.Label style={{ fontWeight: 'bold' }}>Footer Text</Form.Label>
                        <div className="editor-container border rounded">
                          <CKEditor
                            editor={ClassicEditor}
                            config={editorConfig}
                            data={socialLinks?.footerText || ''}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              setSocialLinks((prev) => ({ ...prev, footerText: data }))
                            }}
                          />
                        </div>
                      </Form.Group>
                      {/* Below Products Text */}
                      <h4 className="my-4" style={{ fontWeight: 'bold' }}>Below Products Section</h4>

                      <Form.Group controlId="belowProductTitle" className='mt-2'>
                        <Form.Label style={{ fontWeight: 'bold' }}>Below Product Title</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.belowProductTitle}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({
                              ...prev,
                              belowProductTitle: e.target.value,
                            }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="belowProductHeading1" className='mt-2'>
                        <Form.Label style={{ fontWeight: 'bold' }}>Below Product Heading 1</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.belowProductHeading1}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({
                              ...prev,
                              belowProductHeading1: e.target.value,
                            }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="belowProductDes1" className='mt-2'>
                        <Form.Label style={{ fontWeight: 'bold' }}>Below Product Description 1</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.belowProductDes1}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({
                              ...prev,
                              belowProductDes1: e.target.value,
                            }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="belowProductHeading2" className='mt-2'>
                        <Form.Label style={{ fontWeight: 'bold' }}>Below Product Heading 2</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.belowProductHeading2}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({
                              ...prev,
                              belowProductHeading2: e.target.value,
                            }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="belowProductDes2" className='mt-2'>
                        <Form.Label style={{ fontWeight: 'bold' }}>Below Product Description 2</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.belowProductDes2}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({
                              ...prev,
                              belowProductDes2: e.target.value,
                            }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="belowProductHeading3" className='mt-2'>
                        <Form.Label style={{ fontWeight: 'bold' }}>Below Product Heading 3</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.belowProductHeading3}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({
                              ...prev,
                              belowProductHeading3: e.target.value,
                            }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="belowProductDes3" className='mt-2'>
                        <Form.Label style={{ fontWeight: 'bold' }}>Below Product Description 3</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.belowProductDes3}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({
                              ...prev,
                              belowProductDes3: e.target.value,
                            }))
                          }
                        />
                      </Form.Group>
                      <h4 className="my-4" style={{ fontWeight: 'bold' }}>Product Insights Section</h4>

                      <Form.Group controlId="productInsightsTitle">
                        <Form.Label style={{ fontWeight: 'bold' }}>Product Insights Title</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.productInsightsTitle}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, productInsightsTitle: e.target.value }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="productInsightsHeading1">
                        <Form.Label style={{ fontWeight: 'bold' }}>Product Insights Heading 1</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.productInsightsHeading1}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, productInsightsHeading1: e.target.value }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="productInsightsDes1">
                        <Form.Label style={{ fontWeight: 'bold' }}>Product Insights Description 1</Form.Label>
                        <div className="editor-container border rounded">
                          <CKEditor
                            editor={ClassicEditor}
                            config={editorConfig}
                            data={socialLinks?.productInsightsDes1 || ''}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              setSocialLinks((prev) => ({ ...prev, productInsightsDes1: data }))
                            }}
                          />
                        </div>
                      </Form.Group>

                      {/* -------------- */}
                      <Form.Group controlId="productInsightsITDtitle">
                        <Form.Label style={{ fontWeight: 'bold' }}>Product Insights ITD Heading</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.productInsightsITDtitle}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, productInsightsITDtitle: e.target.value }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="productInsightsITDdes">
                        <Form.Label style={{ fontWeight: 'bold' }}>Product Insights ITD Desciption</Form.Label>
                        <div className="editor-container border rounded">
                          <CKEditor
                            editor={ClassicEditor}
                            config={editorConfig}
                            data={socialLinks?.productInsightsITDdes || ''}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              setSocialLinks((prev) => ({ ...prev, productInsightsITDdes: data }))
                            }}
                          />
                        </div>
                      </Form.Group>
                      {/* --------------------------- */}
                      <Form.Group controlId="productInsightsdPlugtitle">
                        <Form.Label style={{ fontWeight: 'bold' }}>Product Insights dPlug Title</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.productInsightsdPlugtitle}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, productInsightsdPlugtitle: e.target.value }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="productInsightsdPlugdes">
                        <Form.Label style={{ fontWeight: 'bold' }}>Product Insights dPlug Descriptions</Form.Label>
                        <div className="editor-container border rounded">
                          <CKEditor
                            editor={ClassicEditor}
                            config={editorConfig}
                            data={socialLinks?.productInsightsdPlugdes || ''}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              setSocialLinks((prev) => ({ ...prev, productInsightsdPlugdes: data }))
                            }}
                          />
                        </div>
                      </Form.Group>
                      {/* ------------------------------- */}
                      <Form.Group controlId="productInsightsdCaretitle">
                        <Form.Label style={{ fontWeight: 'bold' }}>Product Insights dCare Title</Form.Label>
                        <Form.Control
                          type="text"
                          value={socialLinks?.productInsightsdCaretitle}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({ ...prev, productInsightsdCaretitle: e.target.value }))
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="productInsightsdCaredes">
                        <Form.Label style={{ fontWeight: 'bold' }}>Product Insights dCare Description</Form.Label>
                        <div className="editor-container border rounded">
                          <CKEditor
                            editor={ClassicEditor}
                            config={editorConfig}
                            data={socialLinks?.productInsightsdCaredes || ''}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              setSocialLinks((prev) => ({ ...prev, productInsightsdCaredes: data }))
                            }}
                          />
                        </div>
                      </Form.Group>

                      <Button variant="primary" className="my-2" onClick={handleSaveSettings}>
                        Save Settings
                      </Button>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
